import React, { Fragment, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useParams, useLocation } from "react-router-dom";
import { Result, Button, Drawer, Input, Dropdown, Modal, Divider } from 'antd';
import { derivedConversationDataSelector, derivedConversationId, derivedConversationSource, derivedMerchantId, derivedSubject } from './utils';
import { loadingSelector, emailGroupsSelector, initiateMailSelector } from '@store-inbox/MainPanel/selector';
import { setUploadFile, setActiveID, sendMessage, setEmailUploadFile, changeOwnership, setActiveConversation } from '@store-inbox/ConversationPanel/action';
import { ConversationWrapper, StyledDragComponent, StyledWarning, StyledInfo, StyledEmailAttachment, StyledCloseButton, StyledEmailGroups, StyledEmailReply } from './styled';
import { ReactComponent as AttachmentFailureIcon } from '@assets/icons/message/attachment-failure.svg';
import { fileValidator, acceptedFileTypes, derivedAllTickets } from './utils';
import Conversation from './Conversation';
import Header from './Header/Header';
import TextBox from './TextBox';
import Skeleton from './Skeleton';
import Tags from './Tags';
import { ReactComponent as MusicIcon } from '@assets/icons/message/headphones.svg'
import ReactPlayer from 'react-player'
import { ReactComponent as SendIcon } from '@assets/icons/message/send.svg';
import { uploadStatus, emailFileSelector, ownershipSelector } from '@store-inbox/ConversationPanel/selector';
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";
import Emoji from './TextBox/Emoji';
import { ReactComponent as TypeIcon } from '@assets/icons/message/type.svg';
import { ReactComponent as AlignIcon } from '@assets/icons/message/alignment.svg';
import { ReactComponent as ListIcon } from '@assets/icons/message/list.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/message/link.svg';
import { ReactComponent as UnderlineIcon } from '@assets/icons/message/underline.svg';
import { ReactComponent as BoldIcon } from '@assets/icons/message/bold.svg';
import { ReactComponent as ItalicIcon } from '@assets/icons/message/italic.svg';
import { ReactComponent as ColorIcon } from '@assets/icons/message/text-color.svg';
import { createEditor, Editor, Text, Transforms, Element as SlateElement, Range } from 'slate';
import { Slate, Editable, withReact, ReactEditor, useSelected, useSlate } from 'slate-react'
import { SketchPicker } from 'react-color';
import { ReactComponent as AlignLeftIcon } from '@assets/icons/message/align-left.svg';
import { ReactComponent as AlignCenterIcon } from '@assets/icons/message/align-center.svg';
import { ReactComponent as AlignRighttIcon } from '@assets/icons/message/align-right.svg';
import { ReactComponent as AlignJustifyIcon } from '@assets/icons/message/align-justify.svg';
import { ReactComponent as NumberListIcon } from '@assets/icons/message/number-list.svg';
import { ReactComponent as DocxIcon } from '@assets/icons/message/docx.svg';
import { ReactComponent as PdfIcon } from '@assets/icons/message/pdficon.svg';
import { ReactComponent as GenericIcon } from '@assets/icons/message/genericicon.svg';
import { nanoid } from 'nanoid';
import Attachment from './TextBox/Attachment';
import { ReactComponent as FileIcon } from '@assets/icons/message/docs.svg';
import { toast } from 'react-toastify';
import Carousel from 'react-grid-carousel';
import { withHistory } from 'slate-history';
import { cx, css as Emotioncss } from "@emotion/css";
import isUrl from 'is-url';
import { isKeyHotkey } from 'is-hotkey';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { derivedConnectionsSelector } from '../MainPanel/utils';
import { customerDetailsDataSelector, orderDataSelector } from "@store-inbox/SummaryPanel/selector";
import { derivedAllQuickReplies } from '../QuickReplyPanel/utils';
import { initiateMail } from "@store-inbox/MainPanel/action";

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }
          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
))

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
    <Menu
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          position: relative;
          padding: 1px 18px 17px;
          margin: 0 -20px;
          border-bottom: 2px solid #eee;
          margin-bottom: 20px;
        `
      )}
    />
))

const initialValue = {
    type: 'paragraph',
    children: [{text: ""}],
}

const ConversationPanel = (props) => {
    const {openpanel, setOpenPanel, setDefaultKey, replyPanel,setReplyPanel, input, setInput, newChat, setNewChat, phone, setPhone, template, setTemplate, selectedArray, setSelectedArray} = props;
    
    const dispatch = useDispatch();
    const location = useLocation();
    
    const conversation = useSelector(derivedConversationDataSelector);
    const conversationId = useSelector(derivedConversationId);
    const conversation_source = useSelector(derivedConversationSource);
    const isConversationsLoading = useSelector(loadingSelector);
    // const ticket = useSelector(derivedTicketDataSelector);
    const uploadLoad = useSelector(uploadStatus);
    const convID = useSelector(derivedMerchantId);
    const originalSubject = useSelector(derivedSubject);
    const attachmentUrls = useSelector(emailFileSelector);
    const connections = useSelector(derivedConnectionsSelector);
    const ownership = useSelector(ownershipSelector);
    const allTickets = useSelector(derivedAllTickets);
    const emailGroups = useSelector(emailGroupsSelector);
    const customerData = useSelector(customerDetailsDataSelector);
    const orderData = useSelector(orderDataSelector);
    const replies = useSelector(derivedAllQuickReplies);
    const initiateMailValue = useSelector(initiateMailSelector);
    
    const { id } = useParams();

    const inputElement = useRef();
    const replyListRef = useRef(null);

    const [isOwner, setIsOwner] = useState(false);
    const [file, setFile] = useState([]);
    const [rejectedFile, setRejectedFile] = useState([]);
    const [messages, setMessages] = useState([]);
    const [source, setSource] = useState("");
    const [isSticky, setSticky] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [fileType,setFileType] = useState(null);
    const [fileSize,setFileSize] = useState(null);
    const [videoURL,setVideoURL] = useState([]);
    const [preview,setPreview] = useState([]);
    const [commentReply, setCommentReply] = useState(false);
    const [commentID, setCommentID] = useState(null);
    const [postID, setPostID] = useState(null);
    const [commentName, setCommentName] = useState('');
    const [uploadingStatus, setUploadingStatus] = useState(false);
    const [upload, setUpload] = useState(false);
    const [hideReply,setHideReply] = useState(false);
    const [commentIndex, setCommentIndex] = useState(0);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [compose,setCompose] = useState(false);
    const [ownerStatus, setOwnerStatus] = useState(false);
    const [color, setColor] = useState("#474747");
    const [fromID, setFromID] = useState(null);
    const [cc, setCC] = useState(false);
    const [bcc, setBCC] = useState(false);
    const [ccID, setCcID] = useState([]);
    const [bccID, setBccID] = useState([]);
    const [merchantID,setMerchantID] = useState("");
    const [emailMsgID,setEmailMsgID] = useState("");
    const [toID, setToID] = useState([]);
    const [attachmentData,setAttachmentData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [link,setLink] = useState("");
    const [closeModal,setCloseModal] = useState(false);
    const [ticket, setTicket] = useState({});
    const [notes, setNotes] = useState('');
    const [mailToBeSent, setMailToBeSent] = useState(null);
    const [tempNewEmail, setTempNewEmail] = useState("");
    const [isFocused, setIsFocused] = useState('');
    const [showShortcuts, setShowShortcuts] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [quickReplyInput, setQuickReplyInput] = useState('');
    const [disableSubject , setDisableSubject] = useState(true);
    const [subject, setSubject] = useState(originalSubject);
    const [value, setValue] = useState([initialValue]);

    const basicInfo = customerData && customerData.basic_info;
    const firstOrder =
        orderData &&
        orderData.orders &&
        orderData.orders.length > 0 &&
        orderData.orders[0];

    toast.configure();

    useEffect(() => {
        setSubject(originalSubject);
    }, [originalSubject]);

    useEffect(() => {
        const saved = localStorage.getItem('storeState');
        if (saved) {
            const { auth: { user: { id } } } = JSON.parse(localStorage.getItem('storeState'));
            setCurrentUserId(id);
        }
    }, []);

    useEffect(()=>{
        setAttachmentData([...new Set(attachmentData.concat(attachmentUrls))]);
    },[attachmentUrls])

    useEffect(()=>{
        if(allTickets){
            setTicket({
                id: allTickets[allTickets.length-1].id,
                status: allTickets[allTickets.length-1].status,
                subscribers: allTickets[allTickets.length-1].subscribers,
                tags: allTickets[allTickets.length-1].tags,
                owner_id: allTickets[allTickets.length-1].owner_id,
                priority: allTickets[allTickets.length-1].priority,
                session_expiry_at: allTickets[allTickets.length-1].session_expiry_at,
                is_csat_sent: allTickets[allTickets.length - 1].is_csat_sent,
                expiration_info: allTickets[allTickets.length - 1].expiration_info
            })
        }
    }, [allTickets])

    // useEffect(() => {
    //     if (!isConversationsLoading) {
    //         dispatch(setActiveConversation(id || ''));
    //     }
    // }, [id, isConversationsLoading]);

    useEffect(()=>{
        dispatch(setActiveID(conversationId || ''));
    },[conversationId])

    useEffect(()=>{
        const path = location.pathname;
        const match = path.match(/\/inbox\/tickets\/(\d+)/);
        if (match) {
            dispatch(setActiveConversation(match[1]));
        }
    }, [location])

    useEffect(()=>{
        if(file && fileType && fileType.indexOf("image")!==-1){
            let tempArray = [];
            for (let i = 0; i < file.length; i++) {
                let reader = new FileReader();
                reader.onloadend = function (e){
                    tempArray.push(e.target.result);
                    setPreview([...tempArray]);
                }
                reader.readAsDataURL(file[i]);
            }
        }else if(file && fileType && fileType.indexOf("video"!==-1)){
            let tempArray = [];
            for (let i = 0; i < file.length; i++) {
                tempArray.push(URL.createObjectURL(new Blob([file[i]], {type: Blob})));
                setVideoURL([...tempArray]);
            }
        }else{
            setPreview([]);
            setVideoURL([]);
        }    
    },[file,fileType])

    useEffect(()=>{
        if(uploadLoad){
            setFile([]);
            setUpload(false);
            setUploadingStatus(false);
        }else if(uploadLoad == "error"){
            toast.error("Error. Try uploading again",{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
            });
            setFile(null);
            setUpload(false);
            setUploadingStatus(false);
        }
    },[uploadLoad])

    useEffect(()=>{
        setMerchantID(convID);
    },[convID])

    useEffect(() => {
        if (initiateMailValue.newMail && initiateMailValue.error == null) {
          toast.success(
            `Mail has been sent and ticket id is: ${initiateMailValue.response.ticket_id}`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
        } else if (initiateMailValue.error !== null) {
          const err = initiateMailValue?.error || initiateMailValue;
          if (Object.keys(err.error).length > 0) {
            {
              Object.keys(err.error).map((key) =>
                toast.error(`${key} : ${err.error[key][0]}`, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 6000,
                  hideProgressBar: false,
                  closeOnClick: true,
                })
              );
            }
          } else {
            toast.error(`Mail could not be sent`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
        }
      }, [initiateMailValue]);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        rejectedFiles.forEach((file) => {
            file.errors.forEach((err) => {
              if (err.code === "file-too-large") {
                toast.error("File size exceeds maximum limit of 25 MB",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
              }
    
              if (err.code === "file-invalid-type") {
                toast.error("This file format is not supported.",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
              }
            });
        });
        if(acceptedFiles[0] !== undefined){
            setFile(acceptedFiles);
            setRejectedFile(rejectedFiles);
            setFileType(acceptedFiles[0].type);
            setFileSize(acceptedFiles[0].size);
        }else if([...Array(acceptedFiles)].length > 6){
            toast.error("You cannot send more than 5 files at a time",{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
    }, [ticket]);

    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        validator: fileValidator,
        maxFiles: 5,
        maxSize: 25000000,
        accept: acceptedFileTypes
    })

    const startUpload = () => {
        let formData = new FormData();
        // formData.append('file', file[0]);
        for (let i = 0; i < file.length; i++) {
            formData.append(String(`${i}`), file[i])
        }
        if(source !== "email"){
            dispatch(setUploadFile({
                files: formData,
            }))
        }else{
            dispatch(setEmailUploadFile({
                files: formData,
            }))
        }
    };

    useEffect(() => {
        const { owner_id } = ticket;
        if (currentUserId === owner_id || connections.is_store_admin) {
            setIsOwner(true);
        } else {
            setIsOwner(false);
        }
    }, [currentUserId, ticket, id]);

    useEffect(() => {
        setMessages(conversation);
    }, [conversation]);

    useEffect(()=>{
        if(allTickets && allTickets !== null && allTickets.length > 0){
            const htmlBlocks = convertFromHTML(allTickets[0].notes!== null ? allTickets[0].notes : '');
            let tempNotes = ContentState.createFromBlockArray(
                htmlBlocks.contentBlocks,
                htmlBlocks.entityMap,
            );
            setNotes(EditorState.createWithContent(tempNotes));
        }
    }, [allTickets])

    useEffect(() => {
        setSource(conversation_source);
    }, [conversation_source]);

    const ref = useRef(null);

    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 0);
        }
    };

    const withInlines = editor => {
        const { insertData, insertText, isInline } = editor
      
        editor.isInline = element =>
          ['link', 'button'].includes(element.type) || isInline(element)
      
        editor.insertText = text => {
          if (text && isUrl(text)) {
            wrapLink(editor, text)
          } else {
            insertText(text)
          }
        }
      
        editor.insertData = data => {
          const text = data.getData('text/plain')
      
          if (text && isUrl(text)) {
            wrapLink(editor, text)
          } else {
            insertData(data)
          }
        }
      
        return editor
      }

    const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);

    useEffect(()=>{
        if(ownership){
            setOwnerStatus(true);
            setIsOwner(true);
            if(mailToBeSent!==null && commentID === null){
                const temp_id = nanoid();
                dispatch(sendMessage({
                    message: mailToBeSent,
                    to: toID,
                    cc: ccID,
                    bcc: bccID,
                    temp_id,
                    conversation_source,
                    conversation_id: conversationId,
                    convmsg_message_id: emailMsgID,
                    attachment: attachmentData,
                    from: fromID,
                    subject: subject || 'No subject',
                    ticket_id: ticket.id
                }))
                setMailToBeSent(null);
                setCompose(false);
                setValue([{
                    type: "paragraph",
                    children: [{ text: "" }],
                }]);
                toast.success(`Ticket has been assigned to you`,{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }
        }
    },[mailToBeSent, ownership])

    const handleSubmit = (e)=>{
        e.preventDefault();
        const temp_id = nanoid();
        // if(ticket.status === "unassigned"){
        //     dispatch(changeOwnership({
        //         ticket_ids: [Number(ticket.id)],
        //         transfer_to: connections.current_user_id,
        //     }));
        //     const cleanedHtmlContent = (ReactEditor.toDOMNode(editor, editor).innerHTML).replace(/\$/g, '').replace(/\$&nbsp;/g, '');
        //     setMailToBeSent(cleanedHtmlContent);
        if(toID[0]!=="" && toID.length>0 && toID[0]!==" " && toID[0]!==null){
            if(!disableSubject){
                dispatch(
                    initiateMail({
                        subject: subject,
                        from_email: fromID,
                        to_emails: toID,
                        cc_emails: ccID,
                        bcc_emails: bccID,
                        link_ticket_id: null,
                        email_body: ReactEditor.toDOMNode(editor, editor)
                            .innerHTML.replace(/\$/g, "")
                            .replace(/\$&nbsp;/g, ""),
                        attachment: attachmentData,
                    })
                );
            } else {
                dispatch(sendMessage({
                    message: (ReactEditor.toDOMNode(editor, editor).innerHTML).replace(/\$/g, '').replace(/\$&nbsp;/g, ''),
                    to: toID,
                    cc: ccID,
                    bcc: bccID,
                    temp_id,
                    conversation_source,
                    conversation_id: conversationId,
                    convmsg_message_id: emailMsgID,
                    attachment: attachmentData,
                    from: fromID,
                    subject: subject || 'No subject',
                    ticket_id: ticket.id
                }))
            }
            setCompose(false);
            setValue([{
                type: "paragraph",
                children: [{ text: "" }],
            }]);
            // if(payload.filters.ticket_status ==="assigned"){
            //     dispatch(setSearchFilters({
            //         ...payload,
            //         filters: {
            //             ...payload.filters,
            //             ticket_status: "open"
            //         },
            //         fetch_since: null,
            //         page_size: 25
            //     })
            //     )
            //     setActiveKey("open");
            // }
        } else {
            toast.error("Recipient mail should not be empty",{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
            });
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"]
    const LIST_TYPES = ["numbered-list", "bulleted-list"]

    const BlockButton = ({ format, icon }) => {
        const editor = useSlate();
        return (
          <Button
            active={isBlockActive(
              editor,
              format,
              TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
            )}
            onMouseDown={event => {
              event.preventDefault()
              toggleBlock(editor, format)
            }}
            style={{border: 'none',padding: '0px'}}
          >
          {icon == "list_numbered" ? <ListIcon style={{cursor: 'pointer',stroke: '#474747',height: '24px',width: '24px',marginLeft: '10px',marginBottom: '0'}} /> : icon="list_bulleted" ? <NumberListIcon style={{cursor: 'pointer',stroke: '#474747',marginBottom: '0'}} /> : ""}
              {/*       <Icon>{icon}</Icon> */}
          </Button>
        )
    }

    const isBlockActive = (editor, format, blockType = "type") => {
        const { selection } = editor
        if (!selection) return false
      
        const [match] = Array.from(
          Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n[blockType] === format
          })
        )
      
        return !!match
    }

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )
        const isList = LIST_TYPES.includes(format)
      
        Transforms.unwrapNodes(editor, {
          match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
          split: true
        })
        let newProperties
        if (TEXT_ALIGN_TYPES.includes(format)) {
          newProperties = {
            align: isActive ? undefined : format
          }
        } else {
          newProperties = {
            type: isActive ? "paragraph" : isList ? "list-item" : format
          }
        }

        Transforms.setNodes(editor, newProperties)
      
        if (!isActive && isList) {
          const block = { type: format, children: [] }
          Transforms.wrapNodes(editor, block)
        }
    }

    const handleBold = (e)=>{
        e.preventDefault();
        CustomEditor.toggleBoldMark(editor);
    }

    const handleItalic = (e)=>{
        e.preventDefault();
        CustomEditor.toggleItalicMark(editor);
    }

    const handleUnderline = (e)=>{
        e.preventDefault();
        CustomEditor.toggleUnderlineMark(editor);
    }

    const handleColor = ()=>{
        CustomEditor.toggleColorMark(editor);
    }

    const handleLeftAlign = (e)=>{
        e.preventDefault();
        CustomEditor.toggleLeftMark(editor);
    }

    const handleCenterAlign = (e)=>{
        e.preventDefault();
        CustomEditor.toggleCenterMark(editor);
    }

    const handleRightAlign = (e)=>{
        e.preventDefault();
        CustomEditor.toggleRightMark(editor);
    }

    const handleJustify = (e) =>{
        e.preventDefault();
        CustomEditor.toggleJustifyMark(editor)
    }

    const colorMenu = (
        <SketchPicker onChangeComplete={(e)=>{setColor(e.hex);handleColor();}} />
    )

    const styleMenu = (
        <div style={{background: '#fff',borderRadius: '6px',boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12), 0px -2px 2px rgba(0, 0, 0, 0.12)',padding: '10px'}}>
            <UnderlineIcon onClick={(e)=>handleUnderline(e)} />
            <BoldIcon style={{marginLeft: '20px',cursor: 'pointer'}} onClick={(e)=>handleBold(e)} />
            <ItalicIcon style={{marginLeft: '20px'}} onClick={(e)=>handleItalic(e)} />
            <Dropdown overlay={colorMenu}>
                <ColorIcon style={{marginLeft: '20px'}} />
            </Dropdown>
        </div>
    )

    const alignMenu = (
        <div style={{background: '#fff',borderRadius: '6px',boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12), 0px -2px 2px rgba(0, 0, 0, 0.12)',padding: '10px'}}>
            <AlignLeftIcon style={{cursor: 'pointer'}} onClick={(e)=>handleLeftAlign(e)} />
            <AlignCenterIcon style={{marginLeft: '20px',cursor: 'pointer'}} onClick={(e)=>handleCenterAlign(e)} />
            <AlignRighttIcon style={{marginLeft: '20px',cursor: 'pointer'}} onClick={(e)=>handleRightAlign(e)} />
            <AlignJustifyIcon style={{marginLeft: '20px',cursor: 'pointer'}} onClick={(e)=>handleJustify(e)} />
        </div>
    )

    const listMenu = (
        <div style={{background: '#fff',borderRadius: '6px',boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.12), 0px -2px 2px rgba(0, 0, 0, 0.12)',padding: '5px'}}>
            <BlockButton format="bulleted-list" icon="list_bulleted" />
            <BlockButton format="numbered-list" icon="list_numbered" />
            {/* <ListIcon style={{cursor: 'pointer',stroke: '#474747'}} onClick={()=> CustomEditor.toggleListBlock(editor)} /> */}
            {/* <NumberListIcon style={{marginLeft: '20px',cursor: 'pointer'}} onClick={(e)=> CustomEditor.toggleOrderedListBlock(editor)}  /> */}
        </div>
    )

    // Define a React component renderer for our code blocks.
    const CodeElement = props => {
        return (
        <pre {...props.attributes}>
            <code>{props.children}</code>
        </pre>
        )
    }

    const insertLink = (editor, url) => {
        if (editor.selection) {
          wrapLink(editor, url)
        }
    }

    const isLinkActive = editor => {
        const [link] = Editor.nodes(editor, {
          match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
        })
        return !!link
    }

    const wrapLink = (editor, url) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      
        const { selection } = editor
        const isCollapsed = selection && selection.isCollapsed
        const link = {
          type: 'link',
          url,
          children: isCollapsed ? [{ text: url }] : [],
        }
      
        if (isCollapsed) {
          Transforms.insertNodes(editor, link)
        } else {
          Transforms.wrapNodes(editor, link, { split: true })
          Transforms.collapse(editor, { edge: 'end' })
        }
    }

    const unwrapLink = editor => {
        Transforms.unwrapNodes(editor, {
          match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
        })
    }

    const InlineChromiumBugfix = () => (
        <span
          contentEditable={false}
          className={Emotioncss`
            font-size: 0;
          `}
        >
          ${String.fromCodePoint(160) /* Non-breaking space */}
        </span>
    )

    {/*  {selected && focused && (
                    <div className="popup" style={{background: '#fafafa', marginLeft: '10px',padding: '10px 5px',borderRadius: '10px',marginTop: '-10px'}}>
                        Go to: &nbsp
                        <a href={element.url} rel="noreferrer" target="_blank">
                            {element.url}
                        </a>
                    </div>
                )} */}

    const LinkComponent = ({ attributes, children, element }) => {
        const selected = useSelected();
        return (
                <a
                    {...attributes}
                    href={element.url}
                    style={{textDecoration: 'underline'}}
                    target="_blank"
                    className={
                        selected
                        ? Emotioncss`
                            box-shadow: 0 0 0 3px #ddd;
                            `
                        : ''
                    }
                >
                    <InlineChromiumBugfix />
                    {children}
                    <InlineChromiumBugfix />
                </a>
        )
      }

      const renderElement = useCallback(props => {
        switch (props.element.type) {
            case 'code':
                return <CodeElement {...props} />;
            case 'link':
                return <LinkComponent {...props} />;
            case 'para':
                return <p {...props.attributes}>{props.children}</p>;
            case 'bulleted-list':
                return (
                    <ul style={{ textAlign: props.element.align }} {...props.attributes}>
                        {props.children}
                    </ul>
                );
            case 'list-item':
                return (
                    <li style={{ textAlign: props.element.align }} {...props.attributes}>
                        {props.children}
                    </li>
                );
            case 'numbered-list':
                return (
                    <ol style={{ textAlign: props.element.align }} {...props.attributes}>
                        {props.children}
                    </ol>
                );
            default:
                return <p {...props.attributes}>{props.children}</p>;
        }
    }, []);
    

    useEffect(()=>{
        setCompose(false);
    },[id])

    useEffect(()=>{
        if(!compose){
            const point = { path: [0, 0], offset: 0 };
            editor.selection = { anchor: point, focus: point };

            // For good measure, you can reset the history as well
            editor.history = { redos: [], undos: [] };
            setValue([initialValue]);
            setAttachmentData([]);
        }
    },[compose])

    // Define a React component to render leaves with bold text.
    const Leaf = props => {        
        return (
        <span
            {...props.attributes}
            className={
                props.leaf.text == ''
                  ? Emotioncss`
                      padding-left: 0.1px;
                    `
                  : null
              }
            style={{ fontWeight: (props.leaf.bold) ? 'bold' : '400', fontStyle: (props.leaf.italic) ? 'italic' : '', textDecoration: (props.leaf.underline) ? 'underline' : '', color: (props.leaf.color) ? color : "#474747", textAlign: (props.leaf.left) ? "left" : (props.leaf.center) ? "center" : (props.leaf.right) ? "right" : (props.leaf.justify) ? "justify" : "left", display: (props.leaf.left || props.leaf.right || props.leaf.center || props.leaf.justify) ? 'block' : 'inline' }}
        >
            {props.children}
        </span>
        )
    }

    const CustomEditor = {
        isBoldMarkActive(editor) {
          const [match] = Editor.nodes(editor, {
            match: n => n.bold === true,
            universal: true,
          })
      
          return !!match
        },
        
        isItalicMarkActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.italic === true,
              universal: true,
            })
        
            return !!match
        },

        isUnderlineMarkActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.underline === true,
              universal: true,
            })
        
            return !!match
        },

        isColorMarkActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.color === true,
              universal: true,
            })
        
            return !!match
        },

        isLeftMarkActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.left === true,
              universal: true,
            })
        
            return !!match
        },

        isRightMarkActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.right === true,
              universal: true,
            })
        
            return !!match
        },

        isCenterMarkActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.center === true,
              universal: true,
            })
        
            return !!match
        },

        isJustifyMarkActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.justify === true,
              universal: true,
            })
        
            return !!match
        },

        isCodeBlockActive(editor) {
          const [match] = Editor.nodes(editor, {
            match: n => n.type === 'code',
          })

          return !!match
        },

        isParaBlockActive(editor) { 
            const [match] = Editor.nodes(editor, {
                match: n => n.type === 'para',
            })

            return !!match
        },

        isListBlockActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.type === 'list',
            })
  
            return !!match
        },

        isOrderedListBlockActive(editor) {
            const [match] = Editor.nodes(editor, {
              match: n => n.type === 'orderedList',
            })
  
            return !!match
        },
      
        toggleBoldMark(editor) {
          const isActive = CustomEditor.isBoldMarkActive(editor);
          Transforms.setNodes(
            editor,
            { bold: isActive ? false : true },
            { match: n => Text.isText(n), split: true }
          )
        },
        
        toggleItalicMark(editor) {
            const isActive = CustomEditor.isItalicMarkActive(editor);
            Transforms.setNodes(
              editor,
              { italic: isActive ? false : true },
              { match: n => Text.isText(n), split: true }
            )
        },

        toggleUnderlineMark(editor) {
            const isActive = CustomEditor.isUnderlineMarkActive(editor);
            Transforms.setNodes(
              editor,
              { underline : isActive ? false : true },
              { match: n => Text.isText(n), split: true }
            )
        },

        toggleColorMark(editor) {
            const isActive = CustomEditor.isColorMarkActive(editor);
            Transforms.setNodes(
              editor,
              { color : isActive ? false : true },
              { match: n => Text.isText(n), split: true }
            )
        },

        toggleLeftMark(editor) {
            const isActive = CustomEditor.isLeftMarkActive(editor);
            Transforms.setNodes(
              editor,
              { left : isActive ? false : true },
              { match: n => Text.isText(n), split: true }
            )
        },

        toggleCenterMark(editor) {
            const isActive = CustomEditor.isCenterMarkActive(editor);
            Transforms.setNodes(
              editor,
              { center : isActive ? false : true },
              { match: n => Text.isText(n), split: true }
            )
        },

        toggleRightMark(editor) {
            const isActive = CustomEditor.isRightMarkActive(editor);
            Transforms.setNodes(
              editor,
              { right : isActive ? false : true },
              { match: n => Text.isText(n), split: true }
            )
        },

        toggleJustifyMark(editor) {
            const isActive = CustomEditor.isJustifyMarkActive(editor);
            Transforms.setNodes(
              editor,
              { justify : isActive ? false : true },
              { match: n => Text.isText(n), split: true }
            )
        },

        toggleCodeBlock(editor) {
          const isActive = CustomEditor.isCodeBlockActive(editor)
          Transforms.setNodes(
            editor,
            { type: isActive ? null : 'code' },
            { match: n => Editor.isBlock(editor, n) }
          )
        },

        toggleParaBlock(editor) {
            const { selection } = editor;
        
            if (!selection || Range.isCollapsed(selection)) return;
        
            const [match] = Editor.nodes(editor, {
                match: n => Editor.isBlock(editor, n),
            });
        
            if (!match) return;
        
            const [node, path] = match;
            const isActive = CustomEditor.isListBlockActive(editor) || CustomEditor.isOrderedListBlockActive(editor);
        
            Transforms.setNodes(
                editor,
                { type: isActive ? null : 'para' },
                { at: path } // Ensure only the current block is transformed
            );
        },

        toggleListBlock(editor) {
            const isActive = CustomEditor.isListBlockActive(editor)
            Transforms.setNodes(
              editor,
              { type: isActive ? null : 'list' },
              { match: n => Editor.isBlock(editor, n) }
            )
        },

        toggleOrderedListBlock(editor) {
            const isActive = CustomEditor.isOrderedListBlockActive(editor)
            Transforms.setNodes(
              editor,
              { type: isActive ? null : 'orderedList' },
              { match: n => Editor.isBlock(editor, n) }
            )
        },
    }

    const renderLeaf = useCallback(props =>{
        return <Leaf {...props} />
    })

    const [selection,setSelection] = useState(null);

    const handleTo = (e) => {
        const newList = e.target.value.split(",");
        setTempNewEmail(newList[newList.length - 1] || ""); // Ensure it's a string
        setToID([...new Set(newList)]);
    };

    const handleCc = (e) => {
        const newList = e.target.value.split(",");
        setTempNewEmail(newList[newList.length - 1] || ""); // Ensure it's a string
        setCcID([...new Set(newList)]);
    };

    const handleBcc = (e) => {
        const newList = e.target.value.split(",");
        setTempNewEmail(newList[newList.length - 1] || ""); // Ensure it's a string
        setBccID([...new Set(newList)]);
    };

    // useEffect(()=>{
    //     if(chosenEmoji){
    //         console.log(value[0].children[0]);
    //         value[0].children[0].text = value[0].children[0].text + chosenEmoji.emoji;
    //         setValue([...value, value[0].children[0].text: chosenEmoji.emoji]);
    //         console.log(value[0].children[0].text);
    //            setValue(value[0].children[0].text+chosenEmoji.emoji)
    // }
    // },[chosenEmoji])

    const removeAttachment = (item)=>{
        setAttachmentData(attachmentData.filter(function( obj ) {
            return obj.url !== item;
        })
        )
    }

    const handleInsertLink =()=>{
        setIsModalVisible(true);
    }

    const handleOk = ()=>{
        setIsModalVisible(false);
        insertLink(editor, link);
    }

    const handleFocus = (field) => {
        setIsFocused(field);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused('');
        }, 200); // Delay to allow click event to register
    };

    const handleEmailGroups = (group) => {
        if (isFocused === 'to') {
            setToID([...new Set([...group.email_ids, ...toID.filter(a=>a !== tempNewEmail)])]);
        } else if (isFocused === 'cc') {
            setCcID([...new Set([...group.email_ids, ...ccID.filter(a => a!== tempNewEmail)])]);
        } else if (isFocused === 'bcc') {
            setBccID([...new Set([...group.email_ids, ...bccID.filter(a => a!== tempNewEmail)])]);
        }
        setTempNewEmail("");
    };
    
    const EmailGroupsSection = ()=>{
        return(
            <StyledEmailGroups>
                {emailGroups.filter(a => a.group_name.toLowerCase().includes((tempNewEmail || "").toLowerCase())).map((group) => {
                    return (
                        <div key={group.group_name} onMouseDown={(e) => e.preventDefault()} onClick={() => handleEmailGroups(group)}>
                            <p>{group.group_name}</p>
                            <p>{group.email_ids.map((email) => email).join(" , ")}</p>
                            <Divider style={{ margin: '5px' }} />
                        </div>
                    );
                })}
            </StyledEmailGroups>
        )
    }

    function displayText(template, data) {
        const matcher = /{{(.*?)}}/g;

        return template.replace(matcher, (match) => {
            return data[match.split(/{{|}}/).filter(Boolean)[0].trim()];
        });
    }

    const variables = {
        userName: basicInfo ? basicInfo.first_name + " " + basicInfo.last_name : "NOT_FOUND",
        userEmail: basicInfo && basicInfo.email ? basicInfo.email : "NOT_FOUND",
        userPhone: basicInfo && basicInfo.phone ? basicInfo.phone : "NOT_FOUND",
        userAddress: basicInfo && basicInfo.city ? basicInfo.city : "NOT_FOUND",
        userAddressPinCode:
        basicInfo && basicInfo.pin ? basicInfo.pin : "NOT_FOUND",
        lastOrderNumber: firstOrder ? firstOrder.order_number : "NOT_FOUND",
        lastOrderPrice: firstOrder ? firstOrder.total_order_price : "NOT_FOUND",
        lastOrderTrackingUrl:
        firstOrder && firstOrder.shipment_details
            ? firstOrder.shipment_details.shipment_tracking_url
            : "NOT_FOUND",
        lastOrderAWB:
        firstOrder && firstOrder.shipment_details
            ? firstOrder.shipment_details.shipment_tracking_id
            : "NOT_FOUND",
        lastOrderShippingCompany:
        firstOrder && firstOrder.shipment_details
            ? firstOrder.shipment_details.shipment_company
            : "NOT_FOUND",
        lastOrderShipmentStatus:
        firstOrder && firstOrder.shipment_details
            ? firstOrder.shipment_details.fulfillment_status
            : "NOT_FOUND",
        lastOrderProducts: firstOrder
        ? firstOrder.line_items.map((el) => el.name).join(", ")
        : "NOT_FOUND",
    };

    const insertContent = (content) => {
        const { selection } = editor;
      
        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          
          // Get text from the beginning of the document to the selection point
          const beforeText = Editor.string(editor, {
            anchor: { path: [0], offset: 0 },  // Start from the beginning
            focus: start
          });
      
          // Find the index of the last slash
          const slashIndex = beforeText.lastIndexOf('/');
          
          if (slashIndex !== -1) {
            // Define the range to delete
            const from = { path: start.path, offset: start.offset - (beforeText.length - slashIndex) };
            const to = { path: start.path, offset: start.offset };
            
            // Delete the text from the slash to the current selection
            Transforms.delete(editor, { at: { anchor: from, focus: to } });
          } else {
            // Handle the case where there is no slash
            // Just delete from the current position to the start of selection
            Transforms.delete(editor, { at: { anchor: start, focus: selection.focus } });
          }
      
          // Insert the new content at the current selection
          Transforms.insertText(editor, content, { at: editor.selection });
      
          // Reset input and hide shortcuts
          setQuickReplyInput("");
          setShowShortcuts(false);
        }
    };
    
    useEffect(() => {
        if (replyListRef.current) {
          const selectedElement = replyListRef.current.children[selectedIndex];
          if (selectedElement) {
            selectedElement.scrollIntoView(true);
          }
        }
    }, [selectedIndex, showShortcuts]);

    return (<ConversationWrapper replypanel={replyPanel} conversationSource={conversation_source}  style={{marginRight: openpanel ? '0px' : '10%', marginLeft: '1%'}}>
        {id !== undefined ?
            <div {...getRootProps()}>
                {((file.length > 0 || rejectedFile.length > 0 || isDragActive) && ticket.status!=="closed") ? (
                    <UploadComponent source={source} setUpload={setUpload} setUploadingStatus={setUploadingStatus} uploadingStatus={uploadingStatus} ticket={ticket} preview={preview} fileType={fileType} fileSize={fileSize} videoURL={videoURL} file={file} setFile={setFile} rejectedFile={rejectedFile} setRejectedFile={setRejectedFile} isDragActive={isDragActive} startUpload={startUpload} />
                ) :
                    (<Fragment>
                        <Header setDefaultKey={setDefaultKey} openPanel={openpanel} setOpenPanel={setOpenPanel} conversation_source={source} loading={isConversationsLoading} ticket={ticket} isOwner={isOwner} setIsOwner={setIsOwner} conversationId={conversationId} className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref} messages={messages} notes={notes} setNotes={setNotes} />
                        <Conversation setDisableSubject={setDisableSubject} setValue={setValue} setTicket={setTicket} newChat={newChat} setNewChat={setNewChat} phone={phone} setPhone={setPhone} subject={subject} setCC={setCC} setEmailMsgID={setEmailMsgID} merchantID={merchantID} compose ={compose} setCompose={setCompose} toID={toID} setToID={setToID} setCcID={setCcID} fromID={fromID}  setFromID={setFromID} setCommentIndex={setCommentIndex} setHideReply={setHideReply} setPostID={setPostID} setCommentID={setCommentID} setCommentName={setCommentName} setCommentReply={setCommentReply} setInput={setInput} inputElement={inputElement} conversation_source={source} messages={messages} ticket={ticket} openPanel={openpanel} />
                        <Tags ticket={ticket} isOwner={isOwner} />
                        <TextBox variables={variables} displayText={displayText} setToID={setToID} selectedArray={selectedArray} setSelectedArray={setSelectedArray} template={template} setTemplate={setTemplate} isOwner={isOwner} setIsOwner={setIsOwner} newChat={newChat} setNewChat={setNewChat} phone={phone} setPhone={setPhone} ownerStatus={ownerStatus} setOwnerStatus={setOwnerStatus} chosenEmoji={chosenEmoji} setChosenEmoji={setChosenEmoji} compose={compose} setCompose={setCompose} commentIndex={commentIndex} hideReply={hideReply} postID={postID} setCommentID={setCommentID} commentID={commentID} commentName={commentName} commentReply={commentReply} setCommentReply={setCommentReply} inputElement={inputElement} replies={replies} input={input} setInput={setInput} replyPanel={replyPanel} setReplyPanel={setReplyPanel} conversation_source={source} messages={messages} ticket={ticket} conversationId={conversationId} setDisableSubject={setDisableSubject} />
                        {compose && <Drawer
                            title={<span style={{fontWeight: '600',fontSize: '18px',color: '#474747'}}>Compose</span>}
                            className='email-drawer'
                            placement="bottom"
                            closable={false}
                            onClose={()=>setCompose(false)}
                            visible={compose}
                            maskClosable={false}
                            getContainer={false}
                            style={{ position: 'absolute', width: '95%',transform: 'translate(-50%)',left: '50%',top: '0',bottom: '20px'}}
                            keyboard={false}
                        >
                            <StyledCloseButton style={{margin: '0',position: 'absolute',top: '10px',right: '3.5%',color: '#5B5B5B',fontWeight: '600',cursor: 'pointer',padding: '0px 5px'}} onClick={()=>setCloseModal(true)}>X</StyledCloseButton>
                            <Modal title="" onCancel={()=>setCloseModal(false)} centered visible={closeModal} okText="Yes" cancelText="No" onOk={()=>{setCloseModal(false);setCompose(false);}}><p style={{fontSize: '16px'}}>Discard Email ?</p></Modal>
                            <label for="From" style={{display: 'flex',color: '#5b5b5b',alignItems: 'center',margin: '5px 0'}}>From: <Input placeholder="Sender's mail" type="email" value={fromID} disabled={true} style={{border: 'none',borderBottom: '1px solid #d1d1d1', margin:'0', marginLeft: '10px'}} /> </label>
                            <label for="To" style={{display: 'flex',color: '#5b5b5b',alignItems: 'center',margin: '5px 0'}}>To: <Input required placeholder="Receipient's mail" value={toID} onChange={(e) => handleTo(e)} type="email" style={{border: 'none',borderBottom: '1px solid #d1d1d1',margin:'0',marginLeft: '24px'}}  onFocus={()=>handleFocus('to')} onBlur={handleBlur} /> </label>
                            {isFocused === 'to' && emailGroups.length > 0 && (
                                <EmailGroupsSection />
                            )}
                            <label for="subject" style={{display: 'flex',color: '#5b5b5b',alignItems: 'center',margin: '5px 0'}}>Sub: <Input type="text" placeholder='Subject' value={subject} disabled={disableSubject} onChange={(e)=>setSubject(e.target.value)} style={{border: 'none',borderBottom: '1px solid #d1d1d1',marginLeft: '15px'}}  /></label>
                            <p style={{position: 'absolute',right:'9%',textDecoration: 'underline',color: '#5b5b5b',top: '15%',cursor: 'pointer'}} onClick={()=>{setCC(!cc)}}>Cc</p>
                            <p style={{position: 'absolute',right:'5%',textDecoration: 'underline',color: '#5b5b5b',top: '15%',cursor: 'pointer'}} onClick={()=>{setBCC(!bcc)}}>Bcc</p>
                            {cc && <label for="cc" style={{display: 'flex',color: '#5b5b5b',alignItems: 'center',margin: '5px 0'}}>Cc: <Input placeholder="Cc" value={ccID} onChange={(e) => handleCc(e)} type="email" style={{marginLeft: '20px',border: 'none',borderBottom: '1px solid #d1d1d1'}} onFocus={()=>handleFocus('cc')} onBlur={handleBlur} /> </label> }
                            {isFocused === 'cc' && emailGroups.length > 0 && (
                                <EmailGroupsSection />
                            )}
                            {bcc && <label for="bcc" style={{display: 'flex',color: '#5b5b5b',alignItems: 'center',margin:'5px 0 10px'}}>Bcc: <Input placeholder="Bcc" value={bccID} onChange={(e) => handleBcc(e)} type="email" style={{marginLeft: '0px',border: 'none',borderBottom: '1px solid #d1d1d1',marginLeft: '15px'}} onFocus={()=>handleFocus('bcc')} onBlur={handleBlur} /> </label> }
                            {isFocused === 'bcc' && emailGroups.length > 0 && (
                                <EmailGroupsSection />
                            )}
                            <Slate editor={editor} selection={selection} value={value}>
                                <Editable
                                    className='email-editor'
                                    renderElement={renderElement}
                                    value={value}
                                    placeholder="Compose Email"
                                    spellCheck
                                    autoFocus
                                    renderLeaf={renderLeaf}
                                    style={{marginTop: '10px',overflowY: 'scroll',paddingTop: '10px',lineHeight: '1.5'}}
                                    onPaste={(event) => {
                                        event.preventDefault(); // Prevent default paste behavior
                                    
                                        const pastedText = event.clipboardData.getData('text/plain');
                                        const pastedHTML = event.clipboardData.getData('text/html');

                                        if (pastedText.match(/^https?:\/\/\S+$/)) {
                                            // If the pasted content is a plain URL, convert it to an anchor tag HTML
                                            const url = pastedText;
                                            const anchorText = url;
                                            
                                            // Insert the anchor tag as a link within a text node
                                            const linkNode = { type: 'link', url, children: [{ text: anchorText }] };
                                            Transforms.insertNodes(editor, linkNode);
                                          } else if (pastedHTML) {
                                            // If the pasted content is HTML, insert the HTML content as nodes
                                            const fragment = new DOMParser().parseFromString(pastedHTML, 'text/html');
                                            const children = Array.from(fragment.body.childNodes);
                                      
                                            // Insert each child node, and wrap anchor tags within a link node
                                            const nodesToInsert = children.map(child => {
                                              if (child.nodeName === 'A') {
                                                return { type: 'link', url: child.href, children: [{ text: child.textContent }] };
                                              }
                                              return { type: 'paragraph', children: [{ text: child.textContent }] };
                                            });
                                      
                                            Transforms.insertNodes(editor, nodesToInsert);
                                          } else {
                                            // If no HTML or URL, insert the plain text
                                            Transforms.insertText(editor, pastedText);
                                          }
                                    }}
                                    onKeyDown={event => {

                                        const { selection } = editor

                                        if (selection && selection.isCollapsed) {
                                          const { nativeEvent } = event
                                          if (isKeyHotkey('left', nativeEvent)) {
                                            event.preventDefault()
                                            Transforms.move(editor, { unit: 'offset', reverse: true })
                                            return
                                          }
                                          if (isKeyHotkey('right', nativeEvent)) {
                                            event.preventDefault()
                                            Transforms.move(editor, { unit: 'offset' })
                                            return
                                          }
                                        }

                                        // if(event.key === "Enter"){
                                        //     CustomEditor.toggleParaBlock(editor);
                                        // }
                                        if (event.key === '/') {
                                            setInput('/');
                                            setShowShortcuts(true);
                                            setQuickReplyInput("");
                                            setSelectedIndex(0);
                                        } else if (event.key === 'ArrowDown') {
                                            event.preventDefault();
                                            setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, replies.length - 1));
                                        } else if (event.key === 'ArrowUp') {
                                            event.preventDefault();
                                            setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                                        } else if (event.key === ' ') {
                                            setQuickReplyInput("");
                                            setShowShortcuts(false);
                                        } else if (event.key === 'Backspace') {
                                            setQuickReplyInput((prevInput) => {
                                              const newInput = prevInput.slice(0, -1);
                                              if (newInput === "/") {
                                                setShowShortcuts(true);
                                              } else if (newInput === "") {
                                                setQuickReplyInput("");
                                                setShowShortcuts(false);
                                              }
                                              return newInput;
                                            });
                                        } else if (event.key === 'Enter' && showShortcuts) {
                                            event.preventDefault();
                                            const selectedReply = replies[selectedIndex];
                                            if (selectedReply) {
                                                let content = displayText(selectedReply.message, variables);
                                                insertContent(content);
                                            }
                                        } else if(showShortcuts) {
                                            setQuickReplyInput(quickReplyInput + event.key);
                                        }

                                        if (!event.ctrlKey) {
                                          return
                                        }

                                        switch (event.key) {
                                          // When "`" is pressed, keep our existing code block logic.
                                          case '`': {
                                            event.preventDefault()
                                            CustomEditor.toggleCodeBlock(editor)
                                            break
                                          }
                              
                                          // When "B" is pressed, bold the text in the selection.
                                          case 'b': {
                                            event.preventDefault()
                                            CustomEditor.toggleBoldMark(editor)
                                            break
                                          }

                                          case 'i': {
                                            event.preventDefault()
                                            CustomEditor.toggleItalicMark(editor)
                                            break
                                          }

                                          case 'u': {
                                            event.preventDefault()
                                            CustomEditor.toggleUnderlineMark(editor)
                                            break
                                          }
                                        }
                                      }}
                                />
                                {showShortcuts && replies?.length > 0 && (
                                    <StyledEmailReply>
                                    <ul ref={replyListRef} style={{ listStyle: "none", padding: "0" }}>
                                        {replies
                                        .filter((val) => {
                                            if(quickReplyInput == ""){
                                                return val;
                                            } else if (val.shortcut.toLowerCase().includes(quickReplyInput.toLowerCase())) {
                                                return val;
                                            }
                                        })
                                        .map((reply, i) => {
                                            return (
                                            <Fragment>
                                                <li
                                                className={i === selectedIndex ? "selected" : ""}
                                                style={{ padding: "5px" }}
                                                key={reply.id}
                                                tabIndex={i}
                                                >
                                                <div
                                                    style={{ margin: "15px 0", cursor: "pointer" }}
                                                    onClick={() => {
                                                        let content = displayText(reply.message, variables);
                                                        insertContent(content);
                                                    }}
                                                >
                                                    <p
                                                    style={{
                                                        margin: "0",
                                                        color: "#474747",
                                                        fontSize: "16px",
                                                        fontWeight: "700",
                                                    }}
                                                    >
                                                    {reply.shortcut}{" "}
                                                    <span
                                                        style={{
                                                        color: "#6F6F6F",
                                                        fontSize: "16px",
                                                        fontWeight: "200",
                                                        }}
                                                    >
                                                        - Quick Reply
                                                    </span>
                                                    </p>
                                                    <p
                                                    style={{
                                                        margin: "5px 0 0 0",
                                                        color: "#474747",
                                                        fontSize: "16px",
                                                    }}
                                                    >
                                                    {reply.message}
                                                    </p>
                                                </div>
                                                </li>
                                                <Divider style={{ margin: "0" }} />
                                            </Fragment>
                                            );
                                        })}
                                    </ul>
                                    </StyledEmailReply>
                                )}
                                {attachmentData && attachmentData.length > 0 && <div>
                                {attachmentData.map((data)=>{
                                    return(
                                        <StyledEmailAttachment>
                                            <div>
                                                {data.content_type.indexOf("image") !==-1 ? <img style={{width: '20px',height: '20px'}} src={data.url} alt="Preview" /> : data.content_type.indexOf("video") !== -1 ? <ReactPlayer url={data.url} width="20px" height="20px" /> : data.content_type.indexOf("pdf") ? <PdfIcon width="20px" height="20px" /> : data.content_type.indexOf("docx") ? <DocxIcon width="20px" height="20px" /> : <GenericIcon width="20px" height="20px" /> }
                                                <a href={data.url} target="_blank" rel="noopener noreferrer">{data.file_name}</a>
                                            </div>
                                            <p onClick={()=>removeAttachment(data.url)}>X</p>
                                        </StyledEmailAttachment>
                                    )
                                })}</div>}
                                <Toolbar style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',width: '90%',position: 'fixed',bottom: '0px',background: '#fff',padding: '10px'}}>
                                    <Dropdown overlay={styleMenu} placement="topLeft">
                                        <TypeIcon style={{cursor: 'pointer'}} />
                                    </Dropdown>
                                    {/* <Emoji ownerStatus={ownerStatus} setChosenEmoji={setChosenEmoji} /> */}
                                    <Attachment ownerStatus={ownerStatus} ticket={ticket} conversationSource={source} />
                                    <Dropdown overlay={alignMenu} placement="top">
                                        <AlignIcon style={{cursor: 'pointer'}} />
                                    </Dropdown>
                                    <Dropdown overlay={listMenu} placement="top">
                                        <ListIcon stroke="#474747" style={{cursor: 'pointer'}} />
                                    </Dropdown>
                                    <LinkIcon onClick={()=>handleInsertLink()} />
                                    <Modal title="Add Link" centered onCancel={()=>setIsModalVisible(false)} visible={isModalVisible} onOk={()=>handleOk()} okButtonProps={{style: {background: 'var(--color-primary)'}}}>
                                        <input placeholder='Paste the url here' onChange={(e)=>setLink(e.target.value)} style={{width: '100%',height:'40px',padding: '0 15px'}} />
                                        <button onClick={()=>unwrapLink(editor)} style={{margin: 'auto',marginTop: '10px',border: 'none',background: 'var(--color-primary)',color: '#fff',padding: '10px',borderRadius: '10px',display: 'block'}}>Remove Link</button>
                                    </Modal>
                                    <Button onClick={(e)=>handleSubmit(e)} style={{display: 'flex',fontSize: '16.5px',justifyContent: 'center',alignItems: 'center',height: '40px',width: '100px',border: 'none',borderRadius: '4px'}} type="primary">
                                        Send <SendIcon style={{stroke: '#fff',marginLeft: '8px'}} />
                                    </Button>
                                </Toolbar> 
                            </Slate>
                        </Drawer>
                    }
                    </Fragment>)
                }
            </div>
            : <Skeleton />
        }
    </ConversationWrapper>)
}

export const UploadComponent = (props) => {
    const { ticket, rejectedFile, setRejectedFile, file, setFile, isDragActive, startUpload, videoURL, fileType, preview, fileSize, setUpload, setUploadingStatus, uploadingStatus, source } = props;
    const handleCloseUpload = () => {
        setFile([]);
        setUpload(false);
    };

    const handleCloseRejected = () => {
        setRejectedFile([]);
    };

    const handleUpload = () => {
        startUpload();
        // setFile([]);
        setUploadingStatus(true);
    };

    return (
        <StyledDragComponent>
            {isDragActive ?
                <div>
                    <Button onClick={handleCloseUpload} className='close-button' type='link'>x</Button>
                    <p style={{fontSize: "48px",color: "#000",position: 'absolute',bottom: '20px',transform:"translate(-50%,0%)",left: "50%"}}>Drop files here</p>
                </div>
                :
                rejectedFile.length > 0 ?
                    (<RejectedFileUpload rejectedFile={rejectedFile} handleCloseRejected={handleCloseRejected}></RejectedFileUpload>)
                    :
                    (<AcceptedFileUpload uploadingStatus={uploadingStatus} source={source} videoURL={videoURL} fileSize={fileSize} preview={preview} fileType={fileType} ticket={ticket} acceptedFile={file} handleCloseUpload={handleCloseUpload} handleUpload={handleUpload}></AcceptedFileUpload>)
            }
        </StyledDragComponent>
    );
};

const RejectedText = (props) => {
    const { file: { errors, file } } = props;
    if (errors[0].code === 'file-invalid-type') {
        return <StyledWarning>File <span>{file.name}</span> has a type <span>"{file.type}"</span> which is not supported.</StyledWarning>
    } else if (errors[0].code === 'size-too-large') {
        return <StyledWarning><span>{`${errors[0].fileType} (${file.type})`}</span> larger than <span>{errors[0].size} MB</span> is not supported.</StyledWarning>
    }
    return null;
}


const RejectedFileUpload = (props) => {
    const { handleCloseRejected, rejectedFile } = props;

    return (
        <div>
            <Button onClick={handleCloseRejected} className='close-button' type='link'>x</Button>
            <Result
                icon={<AttachmentFailureIcon />}
                title={<RejectedText file={rejectedFile[0]} />}
            />
        </div>
    );
}

// const AcceptedText = (props) => {
//     const { ticket, file } = props;
//     return <StyledInfo>Sending <span>{file.name}</span> to <span className="info-name">{ticket.whatsapp_first_name} {ticket.whatsapp_last_name}</span></StyledInfo>;
// }

const AcceptedFileUpload = (props) => {
    const { ticket, handleUpload, acceptedFile, handleCloseUpload, videoURL, fileType, preview, fileSize, uploadingStatus, source } = props;
    const previewRef = useRef(null);
    const override = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
    `;
    const PreviewImages = ({prevImageURL})=>{
        return(
            <Carousel cols={1} rows={1} gap={10} containerStyle={{width: '600px'}}>{prevImageURL && prevImageURL.map((prev, i)=>{
                return(
                    <Carousel.Item key={i}>
                        <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginBottom: '20px'}}>{acceptedFile[i].name}</p>
                        <img key={i} style={{margin: '20px auto',display :'block',maxHeight: '400px',objectFit: 'contain',maxWidth: '500px'}} src={prev} alt="Preview" />
                        <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginTop: '10px'}}>{acceptedFile[i].size < 1000000 ? Math.round(((acceptedFile[i].size/1000)*100))/100 + ' KB' : Math.round(((acceptedFile[i].size/1000000)*100))/100 + ' MB'}</p>
                    </Carousel.Item>
                )
            })}</Carousel>
        )
    }

    return (
        <div>
            <Button onClick={handleCloseUpload} className='close-button' type='link'>x</Button>
            {/* <Result
                icon={<AttachmentIcon />}
                title={<AcceptedText ticket={ticket} file={acceptedFile[0]} />}
                extra={
                    <Button size="large" type="primary" icon={<FileAddOutlined />} onClick={handleUpload}>
                        Send File
                    </Button>
                }
            /> */}
            {uploadingStatus && previewRef &&
                <div style={{width: previewRef.current.clientWidth,height: previewRef.current.clientHeight,background: 'rgba(0,0,0,0.8)',position: 'absolute',top: previewRef.current.offsetTop,zIndex: '1',left: previewRef.current.offsetLeft}}>
                    <BarLoader color="#1d8dcc" loading={uploadingStatus} speedMultiplier="0.75" width="220px" height="4px" css={override} /> 
                </div>
            }
            {acceptedFile.length == 1 && <p style={{textAlign:'center',fontSize: "18px",color: "#000"}}>{acceptedFile[0].name}</p>}
            <div ref={previewRef}>
                {(preview && preview.length == 1 && acceptedFile[0].type.indexOf("image")!==-1) ? <img src={preview[0]} alt='' style={{width: '95%',maxHeight: '400px',margin: '20px auto',display :'block'}} /> : 
                (preview && preview.length > 1 && acceptedFile[0].type.indexOf("image")!==-1) ? <PreviewImages prevImageURL={preview} /> : (acceptedFile.length === 1 && acceptedFile[0].type.indexOf("video")!==-1) ? <ReactPlayer controls url={videoURL[0]} style={{margin: '20px',width: '640px',height: '360px'}} /> : (acceptedFile && acceptedFile.length > 1 && acceptedFile[0].type.indexOf("video")!==-1) ? <Carousel cols={1} rows={1} gap={10} containerStyle={{width: '700px'}}>
                {
                    videoURL.map((src, i)=>{
                        return(
                            <Carousel.Item key={i}>
                                <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginBottom: '20px'}}>{acceptedFile[i].name}</p>
                                <ReactPlayer controls url={src} style={{width: '500px'}} />
                                <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginTop: '10px'}}>{acceptedFile[i].size < 1000000 ? Math.round(((acceptedFile[i].size/1000)*100))/100 + ' KB' : Math.round(((acceptedFile[i].size/1000000)*100))/100 + ' MB'}</p>
                            </Carousel.Item>
                        )
                    })
                }
                </Carousel>:
                (acceptedFile.length === 1 && acceptedFile[0].type.indexOf("audio")!==-1) ? <MusicIcon style={{width: '300px',height: '300px',stroke:"#fff",background: "#1D8DCC",padding: "10px",margin: "0px 0"}} /> : (acceptedFile && acceptedFile.length > 1 && acceptedFile[0].type.indexOf("audio")!==-1) ? <Carousel cols={1} rows={1} gap={10}>
                    {
                        Object.keys(acceptedFile).map((i)=>{
                            return (
                                <Carousel.Item key={i}>
                                    <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginBottom: '20px'}}>{acceptedFile[i].name}</p>
                                    <MusicIcon style={{width: '300px',height: '300px',stroke:"#fff",background: "#1D8DCC",padding: "10px",margin: "0px auto",display: 'block'}} />
                                    <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginTop: '10px'}}>{acceptedFile[i].size < 1000000 ? Math.round(((acceptedFile[i].size/1000)*100))/100 + ' KB' : Math.round(((acceptedFile[i].size/1000000)*100))/100 + ' MB'}</p>
                                </Carousel.Item>
                            )
                        })
                    }</Carousel> :
                    (acceptedFile.length == 1) ? <Result icon={<FileIcon style={{width: '200px',height: '200px'}} />} /> : <Carousel>
                    {
                        Object.keys(acceptedFile).map((i)=>{
                            return (
                                <Carousel.Item key={i}>
                                    <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400'}}>{acceptedFile[i].name}</p>
                                    <Result icon={<FileIcon style={{width: '200px',height: '200px'}} />} />
                                    <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400'}}>{acceptedFile[i].size < 1000000 ? Math.round(((acceptedFile[i].size/1000)*100))/100 + ' KB' : Math.round(((acceptedFile[i].size/1000000)*100))/100 + ' MB'}</p>
                                </Carousel.Item>
                            )
                        })
                    }
                    </Carousel>
                }
            </div>
            {fileSize !=null && acceptedFile.length == 1 && 
                <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400'}}>{fileSize < 1000000 ? Math.round(((fileSize/1000)*100))/100 + ' KB' : Math.round(((fileSize/1000000)*100))/100 + ' MB'}</p>
            }
            {source !=="email" ? 
                <Button disabled={uploadingStatus} style={{display: 'flex',fontSize: '16.5px',margin: '0px auto',justifyContent: 'center',alignItems: 'center',height: '50px',width: '150px',border: 'none',borderRadius: '4px'}} type="primary" onClick={handleUpload}>
                    {uploadingStatus ? "Sending..." : "Send"} <SendIcon style={{stroke: '#fff',marginLeft: '8px'}} />
                </Button> 
                : 
                <Button disabled={uploadingStatus} style={{display: 'flex',fontSize: '16.5px',margin: '0px auto',justifyContent: 'center',alignItems: 'center',height: '50px',width: '150px',border: 'none',borderRadius: '4px',marginTop: '10px'}} type="primary" onClick={handleUpload}>
                    {uploadingStatus ? "Attaching..." : "Attach"}
                </Button>
            }
        </div>
    );
}

export default ConversationPanel;